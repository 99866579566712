<template>
  <!-- index:main:bottom START -->
  <div
    class="inner"
    v-if="boxOpened.isOpen"
  >
    <!-- index:main:bottom:content START -->
    <div class="inner">
      <!-- <img width="164" height="" :src="logo.src" style="margin-bottom: 1.5rem;" /> -->
      <!-- eslint-disable-next-line max-len -->
      <div class="text" v-html="$t(`outdoor.index.combi.description.${box.hasAppCheckin}_${box.hasSmsCheckin}_${box.hasCardCheckin}_${box.hasNfcCheckin}`)"></div>
    </div>
    <!-- index:main:bottom:content END -->

    <!-- index:main:bottom:icons START -->
    <div class="icons">

      <!-- index:main:bottom:icons:app START -->
      <div
        class="icon"
        v-if="box.hasAppCheckin === 1"
      >
        <img :src="iconApp.src" />
      </div>
      <!-- index:main:bottom:icons:app END -->

      <!-- index:main:bottom:icons:sms START -->
      <div
        class="icon"
        v-if="box.hasSmsCheckin === 1"
      >
        <img :src="iconSms.src" />
      </div>
      <!-- index:main:bottom:icons:sms END -->

      <!-- index:main:bottom:icons:credit-card START -->
      <div
        class="icon"
        v-if="box.hasCardCheckin === 1"
      >
        <img :src="iconCC.src" />
      </div>
      <!-- index:main:bottom:icons:credit-card END -->

      <!-- index:main:bottom:icons:nfc START -->
      <div
        class="icon"
        v-if="box.hasNfcCheckin === 1"
      >
        <img :src="iconNfc.src" />
      </div>
      <!-- index:main:bottom:icons:nfc END -->

    </div>
    <!-- index:main:bottom:icons END -->

  </div>
  <!-- index:main:bottom END -->
</template>

<script>
import ICONS from '../../../utils/icons';

const {
  iPaymentApp,
  iPaymentSms,
  iPaymentCC,
  iPaymentNfc,
  iEccoviaLogo,
} = ICONS;

export default {
  name: 'box-status',
  props: {
    box: {
      type: Object,
    },
    boxOpened: {
      type: Object,
    },
  },
  data() {
    return {
      logo: {
        src: iEccoviaLogo.defaultUrl,
      },
      iconApp: {
        src: iPaymentApp.defaultUrl,
      },
      iconSms: {
        src: iPaymentSms.defaultUrl,
      },
      iconCC: {
        src: iPaymentCC.defaultUrl,
      },
      iconNfc: {
        src: iPaymentNfc.orangeUrl,
      },
    };
  },
};
</script>
